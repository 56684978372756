import React from 'react';
import style from './Main.module.css';
import MainModal from "./MainModal";
import logo from '../items/logo/moto1312.png'


const Main = () => {
    return (
        <div className={style.back}>
            <img className={style.logo} src={logo}/>
        <MainModal/>
        </div>
    );
};

export default Main;