import React from 'react';
import styles from './ContentModal.module.css';
import {Route, Routes} from "react-router-dom";
import Equpment from "./Equpment";
import Contact from "../Contact";
import Moto from "../moto-card/Moto";
import Consumables from "./Consumables";
import Parts from "./Parts";

const ContentModal = () => {
    return (
        <div className={styles.cntMdl}>
            <Routes>
                <Route path={'/'} Component={Equpment}/>
                <Route path={'/moto'} Component={Moto}/>
                <Route path={'/equpment'} Component={Equpment}/>
                <Route path={'/parts'} Component={Parts}/>
                <Route path={'/consum'} Component={Consumables}/>
                <Route path={'/contact'} Component={Contact}/>
            </Routes>

        </div>
    );
};

export default ContentModal;