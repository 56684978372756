import helmet1 from '../items/helmets/1699449054621.jpg';
import helmet2 from '../items/helmets/1699449054640.jpg';
import helmet3 from '../items/helmets/1699449054631.jpg';
import helmet4 from '../items/helmets/1699449054648.jpg';
import helmet5 from '../items/helmets/1699449054657.jpg';
import helmet6 from '../items/helmets/1699449054667.jpg';
import helmet7 from '../items/helmets/1699449054675.jpg';
import helmet8 from '../items/helmets/1699449054701.jpg';
import helmet9 from '../items/helmets/1699449054710.jpg';
import helmet10 from '../items/helmets/1699449054719.jpg';
import helmet11 from '../items/helmets/1699449054729.jpg';
import helmet12 from '../items/helmets/1699449054737.jpg';
import helmet13 from '../items/helmets/1699449054745.jpg';
import helmet14 from '../items/helmets/1699449054754.jpg';
import helmet15 from '../items/helmets/1699449054762.jpg';

export const helmets = [
    {
        model: 'AHP',
        sizes: "L / M",
        img: helmet1,
        type: "helmet"
    },
    {
        model: 'AHP',
        sizes: "L / M",
        img: helmet2,
        type: "helmet"
    },
    {
        model: 'AHP',
        sizes: "L / M",
        img: helmet3,
        type: "helmet"
    },
    {
        model: 'AHP',
        sizes: "L / M",
        img: helmet4,
        type: "helmet"
    },
    {
        model: 'AHP',
        sizes: "L / M",
        img: helmet5,
        type: "helmet"
    },
    {
        model: 'AHP',
        sizes: "L / M",
        img: helmet6,
        type: "helmet"
    },
    {
        model: 'AHP',
        sizes: "L / M",
        img: helmet7,
        type: "helmet"
    },
    {
        model: 'AHP',
        sizes: "L / M",
        img: helmet8,
        type: "helmet"
    },
    {
        model: 'AHP',
        sizes: "L / M",
        img: helmet9,
        type: "helmet"
    },
    {
        model: 'AHP',
        sizes: "L / M",
        img: helmet10,
        type: "helmet"
    },
    {
        model: 'AHP',
        sizes: "L / M",
        img: helmet11,
        type: "helmet"
    },
    {
        model: 'AHP',
        sizes: "L / M",
        img: helmet12,
        type: "helmet"
    },
    {
        model: 'AHP',
        sizes: "L / M",
        img: helmet13,
        type: "helmet"
    },
    {
        model: 'AHP',
        sizes: "L / M",
        img: helmet14,
        type: "helmet"
    },
    {
        model: 'AHP',
        sizes: "L / M",
        img: helmet15,
        type: "helmet"
    },

]

export const items = [
    {
        id: 1,
        title: "Мотоциклы",
        url: 'moto',
    },
    {
        id: 2,
        title: "Запчасти",
        url: 'parts',
    },
    {
        id: 2,
        title: "Расходники",
        url: 'consum',
    },
    {
        id: 3,
        title: "Экипировка",
        url: 'equpment',
    },
    {
        id: 4,
        title: "контакты",
        url: 'contact',
    },
];

export const subItemsEq = [
    {
        id: 1,
        title: "Шлемы",
        type: 'helmets',
    },
    {
        id: 2,
        title: "Куртки",
        type: 'jackets',
    },
    {
        id: 3,
        title: "Джерси",
        type: 'jersey',
    },
    {
        id: 4,
        title: "Перчатки",
        type: 'gloves',
    },
    {
        id: 5,
        title: "Сумки",
        type: 'bags',
    },
    {
        id: 6,
        title: "Прочее",
        type: 'other',
    },
];

export const subItemsCon = [
    {
        id: 1,
        title: "Свечи",
        type: 'sparks',
    },
    {
        id: 2,
        title: "Масла",
        type: 'oils',
    },
    {
        id: 3,
        title: "Масл. Фильтры",
        type: 'oilFilter',
    },
    {
        id: 4,
        title: "Смазки",
        type: 'grease',
    },
    {
        id: 5,
        title: "Колодки",
        type: 'brakePads',
    },
    {
        id: 6,
        title: "Тормозные диски",
        type: 'brakeDiscs',
    },
];