import React, {useState} from 'react';
import {helmets, subItemsEq} from "../../utils/constants";
import Card from "./card/Card";
import styles from './Equpment.module.css';

const Equpment = () => {

    const [type, setType] = useState("helmets");

    return (
        <div className={styles.frame}>
            <div className={styles.catSelect}>{subItemsEq.map((e, i) =>
                <a
                    className={styles.item}
                    key={i}
                    onClick={
                       () =>{
                           setType(e.type);
                       }
                   }>{e.title}</a>)}</div>
            <div className={styles.cont}>
                {type === "helmets" && helmets.map((e, i) => <Card key={i} img={e.img} model={e.model} size={e.sizes}/>)}
                {type === "jackets" && <p style={{color: "whitesmoke", lineHeight: '2em'}}>Скоро этот раздел будет заполнен...</p>}
                {type === "jersey" && <p style={{color: "whitesmoke", lineHeight: '2em'}}>Скоро этот раздел будет заполнен...</p>}
                {type === "gloves" && <p style={{color: "whitesmoke", lineHeight: '2em'}}>Скоро этот раздел будет заполнен...</p>}
                {type === "bags" && <p style={{color: "whitesmoke", lineHeight: '2em'}}>Скоро этот раздел будет заполнен...</p>}
                {type === "other" && <p style={{color: "whitesmoke", lineHeight: '2em'}}>Скоро этот раздел будет заполнен...</p>}
            </div>
        </div>
    );
};

export default Equpment;