import React from 'react';
import styles from './MainModal.module.css';
import NavBar from "./menu/NavBar";
import ContentModal from "./content/ContentModal";

const MainModal = () => {
    return (
        <div className={styles.modal}>
            <NavBar/>
            <ContentModal/>
        </div>
    );
};

export default MainModal;