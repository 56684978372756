import React, {useEffect, useState} from 'react';
import moto1312 from "../items/contact_imgs/moto1312.png";
import denso from "../items/contact_imgs/Denso.png";
import NGKLogo from "../items/contact_imgs/NGK-logo.png";
import style from  "./Contact.module.css";
import hiflo from "../items/contact_imgs/hiflo-800-4264402717.png";
import trw from "../items/contact_imgs/trw.png";
import honda from "../items/contact_imgs/honda.png";
import kawa from "../items/contact_imgs/kawasaki-brand-png-logo-5.png";
import harley from "../items/contact_imgs/MotoHD.png";
import kews from "../items/contact_imgs/kews-logo.png";

const Contact = () => {

    const [width, setWidth] = useState(false);

    function resize(){
        setWidth( window.innerWidth < 400);
    }

    useEffect(() => {
        window.addEventListener("resize", window);
        resize();
        return window.removeEventListener("resize", window);
    })

    return (
        <div style={{
            width: "100%",
            height: "100%",
            position: "relative",
            overflowY: "hidden",
            display: "flex",
            justifyContent: "space-evenly"
        }}>
            <h3 className={style.third}>Московская славянка, 17А, 2й этаж секция 36а.</h3>
            <h3 className={style.four}><a className={style.tel} href="tel:+79819090535">+7-981-909-05-35</a></h3>
            <img className={style.logo} src={moto1312} alt="moto1312"/>
            <img className={style.denso} src={denso} alt="Denso"/>
            <img className={style.ngk} src={NGKLogo} alt="NGK"/>
            <img className={style.hiflo} src={hiflo} alt="hiflo"/>
            <img className={style.trw} src={trw} alt="NGK"/>
            <img className={style.honda} src={honda} alt="honda"/>
            <img className={style.kawasaki} src={kawa} alt="kawasaki"/>
            <img className={style.harley} src={harley} alt="moto1312"/>
            <img className={style.kews} src={kews} alt="kews"/>
        </div>
    );
};

export default Contact;