import React, {useState} from 'react';
import {helmets, subItemsCon, subItemsEq} from "../../utils/constants";
import Card from "./card/Card";
import styles from './Consumables.module.css';

const Equpment = () => {

    const [type, setType] = useState("sparks");

    return (
        <div className={styles.frame}>
            <div className={styles.catSelect}>{subItemsCon.map((e, i) =>
                <a
                   className={styles.item}
                   key={i}
                   onClick={() => {setType(e.type);}}
                   style={{
                cursor: "pointer",
            }}>{e.title}</a>)}</div>
            <div className={styles.cont}>
                {type === "sparks" && <p style={{color: "whitesmoke", lineHeight: '2em'}}>Скоро этот раздел будет заполнен...</p>}
                {type === "oils" && <p style={{color: "whitesmoke", lineHeight: '2em'}}>Скоро этот раздел будет заполнен...</p>}
                {type === "oilFilter" && <p style={{color: "whitesmoke", lineHeight: '2em'}}>Скоро этот раздел будет заполнен...</p>}
                {type === "grease" && <p style={{color: "whitesmoke", lineHeight: '2em'}}>Скоро этот раздел будет заполнен...</p>}
                {type === "brakePads" && <p style={{color: "whitesmoke", lineHeight: '2em'}}>Скоро этот раздел будет заполнен...</p>}
                {type === "brakeDiscs" && <p style={{color: "whitesmoke", lineHeight: '2em'}}>Скоро этот раздел будет заполнен...</p>}
            </div>
        </div>
    );
};

export default Equpment;