import React from 'react';

const Parts = () => {
    return (
        <div>
            <p style={{color: "whitesmoke", lineHeight: '2em'}}>Скоро этот раздел будет заполнен...</p>
        </div>
    );
};

export default Parts;