import React from 'react';
import styles from "./Moto.module.css"
import kewsMoto from "../../items/moto/kews-k-16.png";

const Moto = ({img, desc}) => {
    return (
        <div className={styles.card}>
           <img className={styles.moto} src={kewsMoto} alt={"KEWS"}/>
            <p className={styles.desc}>

                KEWS K-16 250<br/><br/>

                =========== Двигатель ==========<br/><br/>

                * 4 такта<br/>
                * 250 см3<br/>
                * 5 передач<br/>
                * Электростартер<br/>
                * Кикстартер<br/>
                * Воздушное охлаждение<br/>
                * 95 бензин<br/>
                * Цепной привод ГРМ<br/>
                * Крутящий момент 18 Н.м при 6500 оборотов<br/>
                * Максимальная мошьность 21 л.с. / 14кв.т при 8500 оборотов<br/><br/><br/>

                ======= Тормозная система ======<br/><br/>

                * Гидравлика<br/>
                * Передняя ось : 1 диск / 2х поршневой суппорт<br/>
                * Задняя ось : 1 диск / 1 поршневой суппорт<br/>
                !! Точная копия brembo !!<br/>

                <br/><br/> ===========   Подвеска  ===========<br/><br/>
                Передняя подвеска:<br/>
                * Гидравлическая вилка перевертыш<br/>
                * Регулировка жесткости<br/>
                * Перевертыш 48мм<br/>
                Задняя подвеска:<br/>
                * Гидравлический аммортизатор<br/>
                * Регулировка по жесткости<br/>
                Привод:<br/>
                * Цепь<br/>
                <br/> ============ Размеры =============<br/><br/>

                Колеса.Резина:<br/>
                * Переднее колесо 21" - Резина 100/80<br/>
                * Заднее колесо 18" - Резина 110/100<br/>
                Размеры мотоцикла<br/>
                * Колесная база - 1470 мм<br/>
                * Дорожный просвет 315мм<br/>

                <br/>================================<br/><br/>
                Аптечка, кепка и наклейка в подарок<br/>
            </p>
        </div>
    );
};

export default Moto;