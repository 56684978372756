import React from 'react';
import styles from './Button.module.css'
import {useNavigate} from "react-router-dom";

const Button = ({title, location}) => {

    const navigate = useNavigate();
    return (
        <div className={styles.button} onClick={() => navigate(location)}>
            {title}
        </div>
    );
};

export default Button;