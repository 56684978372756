import React, {useEffect, useRef, useState} from 'react';
import styles from './NavBar.module.css';
import Button from "./Button";
import {items} from "../../utils/constants";

const NavBar = () => {

    const windowWidth = useRef(window.innerWidth);
    let [width, setWidth] = useState(windowWidth.current);

    return (
        <div className={width > 767 ? styles.bar : styles.mobileMenu}>
            {items.map((e,i) => <Button location={e.url} key={i} title={e.title}/>)}
        </div>
    );
};

export default NavBar;