import React from 'react';
import styles from './Card.module.css';

const Card = ({img, model, size}) => {
    return (
        <div className={styles.frame}>
            <img src={img}/>
            <h4>Модель : {model}</h4>
            <h4>Размер : {size}</h4>
        </div>
    );
};

export default Card;